/* jshint ignore:start */
(function (global, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['exports', 'module'], factory);
  } else if (typeof exports !== 'undefined' && typeof module !== 'undefined') {
    factory(exports, module);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, mod);
    global.Plumbing = mod.exports;
  }
})(this, function (exports, module) {
  /**
    @class HttpAdapterScheduledTask
  */
  'use strict';

  var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  var HttpAdapterScheduledTask = (function () {
    /**
      @constructor
      @param options {object}
    */

    function HttpAdapterScheduledTask(options) {
      _classCallCheck(this, HttpAdapterScheduledTask);

      this.adapter = options.adapter;
      this.url = options.url;
      this.handler = options.handler;
      this.self = options.self;
    }

    /**
      @class Adapter
    */

    /**
    */

    _createClass(HttpAdapterScheduledTask, [{
      key: 'run',
      value: function run() {
        var _this = this;

        return this.adapter.request(this.url).then(function (json) {

          _this.handler.call(_this.self, undefined, json);
        })['catch'](function (error) {
          _this.handler.call(_this.self, error, undefined);
        });
      }
    }]);

    return HttpAdapterScheduledTask;
  })();

  var Adapter = (function () {
    function Adapter() {
      _classCallCheck(this, Adapter);
    }

    _createClass(Adapter, [{
      key: 'subscribe',
      value: function subscribe(destination, handler, opt_this) {}
    }, {
      key: 'unsubscribe',
      value: function unsubscribe(destination, handler, opt_this) {}
    }, {
      key: 'send',
      value: function send(destination, body, options) {}
    }]);

    return Adapter;
  })();

  var MimeTypeHelper = (function () {
    function MimeTypeHelper() {
      _classCallCheck(this, MimeTypeHelper);
    }

    /*global HttpAdapterScheduledTask, Adapter, MimeTypeHelper */
    /**
      @enum HttpAdapterState
     */

    _createClass(MimeTypeHelper, null, [{
      key: 'parse',
      value: function parse(rawData) {
        var mimeType, parts, types;
        mimeType = {};
        if(rawData){
          parts = rawData.split(';');
          // base type
          mimeType.baseType = parts[0];
          types = parts[0].split('/');
          mimeType.primaryType = types[0];
          mimeType.subType = types[1];
          parts.shift();
          mimeType.parameters = {};
          parts.map(function (part) {
            var partSplit = part.split('=');
            mimeType.parameters[partSplit[0]] = partSplit[1];
          });
        }
        return mimeType;
      }
    }]);

    return MimeTypeHelper;
  })();

  var HttpAdapterState = {
    READY: 0,
    STARTED: 1,
    FETCHING: 2
  };
  /**
    @class HttpAdapter
   */

  var HttpAdapter = (function (_Adapter) {
    _inherits(HttpAdapter, _Adapter);

    _createClass(HttpAdapter, null, [{
      key: 'defaultHttpAdapterConfig',
      value: function defaultHttpAdapterConfig() {
        return {
          delay: 5000,
          processResponse: function processResponse(response) {
            if (response.ok) {
              var mimeType = MimeTypeHelper.parse(response.headers.get('Content-Type'));
              if(mimeType){
                if (mimeType.baseType === 'application/json') {
                  return response.json();
                } else {
                  // caller will receive the response
                  return Promise.reject(response);
                }
              }
            }
            return Promise.reject(response);
          }
        };
      }
    }]);

    function HttpAdapter(options) {
      _classCallCheck(this, HttpAdapter);

      _get(Object.getPrototypeOf(HttpAdapter.prototype), 'constructor', this).call(this, options);
      var defaultHttpAdapterConf = HttpAdapter.defaultHttpAdapterConfig();
      this.config = options || {};
      this.config.delay = this.config.delay || defaultHttpAdapterConf.delay;
      this.config.processResponse = this.config.processResponse || defaultHttpAdapterConf.processResponse;
      this.state = HttpAdapterState.READY;
      this.scheduledTasks = [];
      this.scheduledTasksCount = 0;
      this.headers = options.headers;
    }

    /*global HttpAdapter, StompOverWebSocketAdapter */

    _createClass(HttpAdapter, [{
      key: 'subscribe',
      value: function subscribe(destination, handler, opt_this) {
        var opts = {
          adapter: this,
          url: destination,
          handler: handler,
          self: opt_this
        };
        this.scheduledTasks.push({
          opts: opts,
          task: new HttpAdapterScheduledTask(opts)
        });
        this.scheduledTasksCount++;
        this.start();
      }
    }, {
      key: 'unsubscribe',
      value: function unsubscribe(destination, handler, opt_this) {
        var _this2 = this;

        var unsubscribeIdx = [];
        this.scheduledTasks.forEach(function (scheduledTask, index) {
          if (scheduledTask.opts.url === destination && (!handler || scheduledTask.opts.handler === handler) && (!opt_this || scheduledTask.opts.self === opt_this)) {
            // Reverse order for suppression from end
            unsubscribeIdx.unshift(index);
          }
        });
        unsubscribeIdx.forEach(function (index) {
          _this2.scheduledTasks.splice(index, 1);
          _this2.scheduledTasksCount--;
        });
        if (this.scheduledTasksCount === 0) {
          this.stop();
        }
      }
    }, {
      key: 'send',
      value: function send(destination, body, options) {
        var url = undefined;
        if (typeof destination === 'function') {
          url = destination.call(this);
        } else {
          url = destination;
        }
        var method = 'get';
        if (typeof options.operation !== 'undefined') {
          if (options.operation === 'insert') {
            method = 'post';
          } else if (options.operation === 'delete') {
            method = 'delete';
            if (options.itemId) {
              url = url + '/' + options.itemId;
            }
          } else if (options.operation === 'update') {
            method = 'put';
            if (options.itemId) {
              url = url + '/' + options.itemId;
            }
          } else if (options.operation === 'read') {
            var _method = 'get';
            if (options.itemId) {
              url = url + '/' + options.itemId;
            }
          }
        }
        var body_ = undefined;
        if (typeof body === 'object' && options.operation !== 'delete') {
          body_ = JSON.stringify(body);
        }
        var headers = {
          'Content-Type': 'application/json'
        };
        // Object.assign(headers, this.headers);
        if (typeof url !== 'undefined') {
          var p = fetch(url, {
            method: method,
            headers: headers,
            body: body_,
            credentials: 'include'
          });
          if (method !== 'delete') {
            return p.then(this.config.processResponse);
          }
          return p;
        } else {
          return Promise.reject('url is undefined');
        }
      }

      //
      // HttpAdapter specific
    }, {
      key: 'request',
      value: function request(destination, responseHandler) {
        var url = undefined;
        if (typeof destination === 'function') {
          url = destination.call(this);
        } else {
          url = destination;
        }
        if (typeof url !== 'undefined') {
          return fetch(url, {
            headers: this.headers,
            credentials: 'include'
          }).then(this.config.processResponse)['catch'](this.config.processResponse);
        } else {
          return Promise.reject('url is undefined');
        }
      }
    }, {
      key: 'run',
      value: function run() {
        var _this3 = this;

        if (this.state === HttpAdapterState.STARTED) {
          (function () {
            _this3.state = HttpAdapterState.FETCHING;
            var scheduledTasksPromises = [];
            _this3.scheduledTasks.forEach(function (scheduledTask) {
              scheduledTasksPromises.push(scheduledTask.task.run());
            });
            Promise.all(scheduledTasksPromises).then(function () {
              _this3.timeout = setTimeout(function () {
                _this3.run();
              }, _this3.config.delay);
              if (_this3.state === HttpAdapterState.FETCHING) {
                _this3.state = HttpAdapterState.STARTED;
              }
            })['catch'](function (error) {
              _this3.timeout = setTimeout(function () {
                _this3.run();
              }, _this3.config.delay);
              if (_this3.state === HttpAdapterState.FETCHING) {
                _this3.state = HttpAdapterState.STARTED;
              }
              throw error;
            });
          })();
        }
      }
    }, {
      key: 'start',
      value: function start() {
        if (this.state === HttpAdapterState.READY) {
          this.state = HttpAdapterState.STARTED;
          this.run();
        }
      }
    }, {
      key: 'stop',
      value: function stop() {
        if (this.state === HttpAdapterState.STARTED) {
          this.state = HttpAdapterState.READY;
          clearTimeout(this.timeout);
        }
      }
    }, {
      key: 'refresh',
      value: function refresh() {
        if (this.scheduledTasksCount > 0) {
          if (this.state === HttpAdapterState.STARTED) {
            clearTimeout(this.timeout);
            this.run();
          } else {
            this.start();
          }
        }
      }
    }]);

    return HttpAdapter;
  })(Adapter);

  var Adapters = (function () {
    function Adapters() {
      _classCallCheck(this, Adapters);
    }

    /*global Adapters */
    /**
     @class Plumbing
     */

    _createClass(Adapters, null, [{
      key: 'getAdapter',

      /**
        @param options {object}
      */
      value: function getAdapter(options) {
        if (options.adapter === 'http') {
          return new HttpAdapter(options, this);
        }
      }
    }]);

    return Adapters;
  })();

  var Plumbing = (function () {
    _createClass(Plumbing, null, [{
      key: 'defaultPlumbingOptions',

      /**
       Default Plumbing options
       */
      value: function defaultPlumbingOptions() {
        return {
          adapter: 'http'
        };
      }

      /**
        @param options {string | object}
      */
    }]);

    function Plumbing(options) {
      _classCallCheck(this, Plumbing);

      var opts = Plumbing.defaultPlumbingOptions();
      if (typeof options === 'string' || typeof options === 'function') {
        opts.url = options;
      } else if (typeof options === 'object') {
        opts.url = options.url;
        opts.adapter = options.adapter || opts.adapter;
        opts.headers = options.headers;
        opts.preprocessor = options.preprocessor;
        // adapter options
        opts.delay = options.delay;
        opts.processResponse = options.processResponse;
      }
      this.url = opts.url;
      this.adapter = Adapters.getAdapter(opts);
      this.handlers = {};
      this.preprocessor = opts.preprocessor;
    }

    /**
     @param type {string}
     @param callback {function}
     @param ctx {object | undefined} the *this* to use in the listener
     */

    _createClass(Plumbing, [{
      key: 'on',
      value: function on(type, callback, ctx) {
        var listeners = this.handlers[type];
        if (typeof listeners === 'undefined') {
          listeners = this.handlers[type] = [];
        }
        var index = -1;
        for (var i = 0; i < listeners.length; i++) {
          var l = listeners[i];
          if (l.callback === callback && l.ctx == ctx) {
            index = i;
            break;
          }
        }
        if (index === -1) {
          index = listeners.length;
          listeners.push({ callback: callback, ctx: ctx });
          if (typeof this.listenersCount === 'undefined') {
            this.listenersCount = 1;
            this.adapter.subscribe(this.url, this.onmessage, this);
          } else {
            this.listenersCount++;
          }
        }
        return index;
      }

      /**
       @param type {string}
       @param callback {function}
       @param opt_this {object | undefined} the *this* to use in the listener
       */
    }, {
      key: 'once',
      value: function once(type, callback, ctx) {
        var listenerProxy = function listenerProxy() {
          callback.apply(ctx, arguments);
          this.off(type, listenerProxy, ctx);
        };
        this.on(type, listenerProxy, ctx);
      }

      /**
       @param type {string}
       @param callback {function}
       */
    }, {
      key: 'off',
      value: function off(type, callback, ctx) {
        var index = -1;
        var listeners = this.handlers[type];
        if (typeof listeners !== 'undefined') {
          if (typeof callback === 'function') {
            for (var i = 0; i < listeners.length; i++) {
              var l = listeners[i];
              if (l.callback === callback && l.ctx == ctx) {
                index = i;
                break;
              }
            }
            if (index !== -1) {
              this.listenersCount--;
              listeners.splice(index, 1);
            }
          } else if (typeof callback === 'undefined') {
            this.listenersCount -= listeners.length;
            listeners.splice(0, listeners.length);
          }
          if (this.listenersCount === 0) {
            delete this.listenersCount;
            this.adapter.unsubscribe(this.url, this.onmessage, this);
          }
        }
        return index;
      }

      /**
        @param val {object}
        @param options {object}
        @return {Promise}
      */
    }, {
      key: 'get',
      value: function get(options) {
        var options_ = options || {};
        options_.operation = 'read';
        return this.adapter.send(this.url, undefined, options_);
      }

      /**
        @param val {object}
        @param options {object}
        @return {Promise}
      */
    }, {
      key: 'set',
      value: function set(val, options) {
        var options_ = options || {};
        options_.operation = 'update';
        return this.adapter.send(this.url, val, options_);
      }

      /**
        @param val {object}
        @param options {object}
        @return {Promise}
      */
    }, {
      key: 'add',
      value: function add(val, options) {
        var options_ = options || {};
        options_.operation = 'insert';
        return this.adapter.send(this.url, val, options_);
      }

      /**
        @param val {object}
        @param options {object}
        @return {Promise}
      */
    }, {
      key: 'remove',
      value: function remove(val, options) {
        var options_ = options || {};
        options_.operation = 'delete';
        return this.adapter.send(this.url, val, options_);
      }
    }, {
      key: 'onmessage',
      value: function onmessage(error, message) {
        if (!error && typeof this.preprocessor === 'function') {
          this.preprocessor.call(this, message);
        }
        var listeners = this.handlers['change'];
        listeners.forEach(function (listener) {
          listener.callback.call(listener.ctx, error, message);
        });
      }
    }, {
      key: 'refresh',
      value: function refresh() {
        this.adapter.refresh();
      }
    }]);

    return Plumbing;
  })();

  module.exports = Plumbing;
});
/* jshint ignore:end */
