import 'es6-promise';
import 'whatwg-fetch';

// We need to ensure jQuery is loaded before angular and is globally set.
import jQuery from 'jquery';

window.$ = jQuery;
window.jQuery = jQuery;

// HACK to fix angular-perfect-scrollbar
import Ps from 'perfect-scrollbar'
window.Ps = Ps; 

// Inject daterangepicker
import daterangepicker from 'bootstrap-daterangepicker'
window.daterangepicker = daterangepicker;

// TODO: Modules should import this as needed
import Plumbing from './js/plumbing/plumbing'
window.Plumbing = Plumbing;

import FastClick from 'fastclick'
window.FastClick = FastClick;

import toastr from 'toastr'
window.toastr = toastr;

import ol from 'ol'
window.ol = ol;

import * as _ from 'lodash'
window._ = _;

import moment from 'moment-timezone'
window.moment = moment;
